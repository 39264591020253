import { Injectable } from '@angular/core';
import { keycodes } from '../../core/keycodes';
import $ from 'jquery';
import { isTouchDevice } from '@proman/utils';

@Injectable()
export class BarcodeScannerService {
    isTouch: boolean = isTouchDevice();
    constructor() { }

    listen = (handler: (data: unknown) => void) => {
        let buffer: string[] = [];
        let threshold: number = this.isTouch ? 120 : 60; // ms
        let timer: any = null;

        function notify() {
            let input = buffer.join('').trim();

            clearTimeout(timer);
            buffer.length = 0;
            handler(input);
        }

        $('body').on('keypress.scanner', (event) => {
            if (timer) clearTimeout(timer);

            timer = setTimeout(notify, threshold);

            if (event.charCode == keycodes.enter) {
                event.stopImmediatePropagation();

                return false;
            }

            buffer.push(String.fromCharCode(event.charCode));
        });
    };

    destroy() {
        $('body').off('keypress.scanner');
    }

}
